@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.jobs-main {
    margin-top: 5rem;
    background-color: white;

    h1 {
        text-align: center;
    }

    &__tag {
        font-size: 1.7rem;
        text-align: center;
    }
}
.jobs {
    /* margin: 2rem; */
    padding: 1rem;
    background: #00214e;
    color: #fff;

    @include tablet {
        padding: 1rem 5rem;
    }

    h2 {
        text-align: center;
    }
    &__section {
        padding: 1rem;
        color: black;
        background-color: $yellow;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
        margin-bottom: 2rem;
    }

    &__paragraph {
        margin-bottom: 1rem;
    }

    &__link {
        color: $yellow;
    }
}

.audition {
    &__link {
        color: blue;
    }
}
.instruments {
    margin: 1rem;

    h2 {
        text-align: center;
    }
    &__dropdown {
        text-align: center;
        border: 5px $yellow solid;
        padding: 1rem;
        width: auto;
        margin: 0 auto;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;

        @include tablet {
            width: 50%;
        }
    }
    &__instrument {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 1rem 0 1rem 0;
        border-bottom: 2px solid black;
        scroll-margin-top: 5rem;

        @include tablet {
            &:nth-child(odd) {
                flex-direction: row;
            }
            &:nth-child(even) {
                flex-direction: row-reverse;
            }
            border-bottom: none;
        }
    }
    &__img {
        width: 100%;

        @include tablet {
            width: 50%;
        }
    }
    &__description {
        margin: 0 1rem 0 1rem;
    }
    &__description p {
        padding: 0;
    }

    &__link {
        color: $dark-blue;
        background-color: $yellow;
        display: flex;
        justify-content: center;
        width: 12rem;
        margin: 1rem auto;
        border-radius: 1rem;
    }
}
