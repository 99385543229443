.contact {
  display: flex;
  flex-direction: column;
  margin: auto;
  
  &__user-name,
  &__user-email,
  &__message-label {
    margin: auto;
    color: white;
  }
  &__user-name{
    display: flex;
  }

  &__message{
    margin: auto;
    height: 143px;
    width: 84%;
    margin-bottom: 15px;
    }

    &__message-label {
        margin: 15px 0 10px 0;
        text-align: center;
    }

    &__submit-button {
        background-color: #ffcc01;
        width: 145px;
        height: 40px;
        border-radius: 25px;
        color: black;
        font-size: 14px;
        cursor: pointer;
        margin: 0px auto;
        margin-bottom: 75px;
    }
}


