@use "../../styles/partials/mixins" as *;

//Note-to-self: Footer on Home page vs. all other pages still needs fixing.

.footer {
    background-color: #00214e;
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;

    @include tablet {
        flex-direction: row;
        justify-content: space-evenly;
    }

    &__logos {
        display: flex;
        flex-direction: row;
    }

    &__a {
        color: white !important;
        font-size: 0.75rem;

        &:hover {
            color: #ffcc01 !important;
        }
    }
    &__img-a {
        text-align: center;
        /*     align-items: center;
        display: flex;
        width: 25%; */
        /*  margin-right: 2rem; */
    }
    &__img {
        width: 10rem;
    }

    &__all-links {
        display: flex;
        justify-content: space-evenly;
        margin-top: auto;
        margin-bottom: 2rem;

        @include tablet {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
        }
    }

    &__links-div {
        display: flex;
        flex-direction: column;
    }
    &__h3 {
        font-weight: 600;
        margin-block-end: 5px;
        color: white;
        font-size: 1rem;
    }
}
