@use "variables" as *;

@mixin mobile {
    @media screen and (min-width: $mobile-width) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: $tablet-width) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-width) {
        @content;
    }
}
