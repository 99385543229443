@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.navbar {
    display: flex;
    align-items: center;
    background-color: $dark-blue;
    height: 65px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    &__logo {
        width: 3rem;
    }

    &__link {
        color: white;
        transition: color 0.3s;
        &:hover {
            color: red;
        }
        &--active {
            color: $yellow;
        }
    }

    &__hamburger {
        display: block;
        cursor: pointer;

        @include tablet {
            display: none;
        }

        &--line {
            width: 30px;
            height: 3px;
            background-color: white;
            margin: 5px;
        }
    }

    &__links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 65px;
        width: 11rem;
        background-color: $dark-blue;
        padding: 10px;

        &--open {
            display: flex;
        }
        @include tablet {
            display: flex;
            flex-direction: row;
            width: 100%;
            background-color: #00214e;
            padding: 10px;
            justify-content: space-evenly;
            align-items: center;
            top: 0;
        }
    }
}
