@use "../../styles/partials/mixins" as *;

.contact-container {
    text-align: center;
    margin-top: 5rem;
    background-color: white;

    &__h1 {
        font-weight: 600;
        color: #00214e;
    }
}
.request {
    width: 100%;
    margin: 0 auto;
    background-color: #00214e;
    margin-block-end: -1em;

    &__h2 {
        color: white;
        padding-top: 15px;
    }

    &__p {
        padding: 1rem;
        line-height: 19px;
        color: white;
        text-align: left;

        @include tablet {
            padding: 1rem 10rem;
        }
    }
    &__info {
        color: #ffcc01 !important;
        font-size: 16px;
        text-decoration: underline !important;
    }
}

.email {
    background-color: #00214e;

    &__h2 {
        color: white;
    }
    &__p {
        padding: 1rem;
        line-height: 19px;
        color: white;
        text-align: left;

        @include tablet {
            padding: 1rem 10rem;
        }
    }
}

.socialLogo {
    width: 2rem;
    margin: 10px;
}
