@use "./styles/partials/variables" as *;

#root {
  min-width: 100%;
  height: 100%;
  font-family: "Quicksand", sans-serif;
  //background-color: $dark-blue;
}

a[href="http://www.w3counter.com"]
{
  display: none;
}
