@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.terms-of-use {
    margin-top: 5rem;
    text-align: center;
    background-color: white;
    padding: 0rem 1rem;

    @include tablet {
        padding: 0rem 3rem;
    }
    @include desktop {
        padding: 0rem 7rem;
    }

    &__section {
        background-color: $yellow;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
        margin-bottom: 2rem;
    }
}

.terms-description {
    padding: 0 1rem 0 1rem;
    text-align: left;

    @include tablet {
        padding: 0 3rem 0 3rem;
    }
}

.link {
    color: #d70f00;
}

.terms-list {
    text-align: left;
}
