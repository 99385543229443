.scrollToTop {
  position: fixed;
  bottom: 20px;
  right: 0;

    &__upContainer {
        float: right;
        display: flex;
        flex-direction: column;
        border: none;
        outline: none;
        background: #00000000;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 35px;
        padding: 5px;
        text-align: center;
        -webkit-border-radius: 35px;
        -moz-border-radius: 35px;
        -ms-border-radius: 35px;
        -o-border-radius: 35px;
    }
    &__upContainer:before {
        content: "";
        background: linear-gradient(45deg, #00214e, #ffcc01, black, lightgray);
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        border-radius: 35px;
        -webkit-border-radius: 35px;
        -moz-border-radius: 35px;
        -ms-border-radius: 35px;
        -o-border-radius: 35px;
    }
    &__upContainer:active {
        color: #000;
    }
    &__upContainer:active:after {
        background: transparent;
    }
    &__upContainer:hover:before {
        opacity: 1;
    }
    &__upContainer:after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 35px;
        -webkit-border-radius: 35px;
        -moz-border-radius: 35px;
        -ms-border-radius: 35px;
        -o-border-radius: 35px;
    }

    &__img {
        width: 30px;
        height: 50px;
    }
}
