//screen widths
$mobile-width: 30rem; //480px;
$tablet-width: 48rem; //768px;
$desktop-width: 80rem; //1280px;

//colors
$background-color: lightgray;
$dark-blue: #00214e;
$black: black;
$white: white;
$yellow: #ffcc01;
