.history {
  text-align: center;
  margin-top: 5rem;
  background-color: white;

  &__description {
    font-size: 1.2rem;
  }

  &__paragraph {
    text-align: left;
    padding: 0rem 1rem;
    line-height: 31px;
    color: #00214e;
  }
}
#about-page-pText {
  font-size: 20px;
  color: #00214e;
  padding: 0px;
  margin-top: -10px;
}
