@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.homeContainer {
  position: relative;
  top: 0;
  left: 0;
  background-image: url(https://res.cloudinary.com/dfbuwtl5q/image/upload/v1674700058/BandParadeNYC_v1cnja.webp);
  background-size: cover;
  height: 100vh;
  width: 100vw;

  &__homePageLogoImg {
    position: absolute;
    top: 15rem;
    margin-left: 23%;
    background-color: rgba(0, 0, 0, 0.62);
    width: 50%;
    z-index: 2;
    border-radius: 2rem;
    padding: 0px 10px 0px 0px;
    animation: fadeIn 3.5s;
    -webkit-animation: fadeIn 3.5s;
  }
}
